<template>
  <div>
    <title>SIM INVENTORY ~ ITEM DATA / MATERIAL DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Master Item Data
        <br />
        <h4>The following is a list of item master data lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->

      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="addData" class="col-md-4">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Item Master Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Item code</label>
                  <input
                    type="text"
                    v-model="isidata.kodebarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item code"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name</label>
                  <input
                    type="text"
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <select2
                    :data="unit"
                    :value="valueunit"
                    @update="updateunit($event)"
                  ></select2>
                  <!-- <select class="form-control" v-model="isidata.unitt" id="unit">
                    <option disabled>--Please Select--</option>
                   <option
                      v-for="unitdata in unit"
                      :key="unitdata.id"
                      :value="unitdata.unit"
                    >
                      {{ unitdata.unit }}
                    </option>
                  </select> -->
                  <!-- <input
                    type="text"
                    v-model="isidata.unit"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Unit"
                  /> -->
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Type</label>
                  <select2
                    :data="tipebrg"
                    :value="valuetype"
                    @update="updatetype($event)"
                  ></select2>
                  <!-- <select class="form-control" v-model="isidata.tipebarang" id="proses">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="brgtipe in tipebrg"
                      :key="brgtipe.id"
                      :value="brgtipe.tipe"
                    >
                      {{ brgtipe.tipe }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <div v-if="isFrmeditData" class="col-md-4">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Item Master Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Item code</label>
                  <input
                    type="text"
                    v-model="isidata.kodebarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item code"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name</label>
                  <input
                    type="text"
                    v-model="isidata.namabarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Item name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <select2
                    :data="unit"
                    :value="valueunit"
                    @update="updateunit($event)"
                  ></select2>
                  <!-- <select class="form-control" v-model="isidata.unitt" id="unit">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="unitdata in unit"
                      :key="unitdata.id"
                      :value="unitdata.unit"
                    >
                      {{ unitdata.unit }}
                    </option>
                  </select> -->
                  <!-- <input
                    type="text"
                    v-model="isidata.unit"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Unit"
                  /> -->
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Type</label>
                  <select2
                    :data="tipebrg"
                    :value="valuetype"
                    @update="updatetype($event)"
                  ></select2>
                  <!-- <select class="form-control" v-model="isidata.tipebarang" id="proses">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="brgtipe in tipebrg"
                      :key="brgtipe.id"
                      :value="brgtipe.tipe"
                    >
                      {{ brgtipe.tipe }}
                    </option>
                  </select> -->
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <button @click="awaldepan()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-8">
          <!--<router-link to="/add-barang">
              <button type="button" class="btn btn-info">
                <i class="fa fa-plus"> Plus Item </i>
              </button>
            </router-link>
          <br /><br />-->
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Master Item Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by item code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Data</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Item Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classbrg, index) in list_paged"
                      :key="classbrg.id"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classbrg.idx }}</td> -->
                      <td>{{ classbrg.kode_barang }}</td>
                      <td>{{ classbrg.nama_barang }}</td>
                      <td>{{ classbrg.unit }}</td>
                      <td>{{ classbrg.tipe }}</td>

                      <td>
                        <!--<router-link :to="'/edit-barang/' + classbrg.id">
                          <button title="Edit Item" class="btn btn-info">
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp;-->
                        <button
                          @click="EditData(classbrg.id)"
                          title="Edit Item"
                          class="btn btn-info"
                        >
                          <i class="fa fa-edit"></i></button
                        >&nbsp;
                        <!--<button
                          @click="AddAlias(classbrg.id)"
                          title="Add Alias"
                          class="btn btn-success"
                        >
                          <i class="fa fa-plus"></i>
                        </button>&nbsp;-->
                        <router-link
                          :to="'/detail-barang-alias/' + classbrg.id"
                        >
                          <button
                            title="View Item Details"
                            class="btn btn-warning"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link>
                        &nbsp;
                        <button
                          title="Delete Item"
                          @click="deleteData(classbrg.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Data</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Item Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="6" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>

      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isidata: {
        kodebarang: "",
        namabarang: "",
        tipebarang: "",
        idxbarang: "",
        kodebarangalias: "",
        aliasbarang: "",
        namasupplier: "",
        unitt: "",
        kodesupplier: ""
      },
      valueunit: "",
      valuetype: "",
      addData: true,
      isFrmeditData: false,
      isExist: true,
      addAlias: false,
      loading: false,
      namesearch: "",
      list_paged: [],
      tipebrg: [],
      unit: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    this.getunit();
    this.getkategoribarang();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchData();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    awaldepan() {
      this.addData = true;
      this.isFrmeditData = false;
      this.addAlias = false;
      this.isidata.kodebarang = "";
      this.isidata.namabarang = "";
      this.valueunit = "";
      this.valuetype = "";
      this.fetchData();
    },
    updateunit(value) {
      this.valueunit = value;
    },
    updatetype(value) {
      this.valuetype = value;
    },
    getunit() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "unit/getalldataunit?length=2000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.unit = resp.data.data;
          this.unit.forEach((item) => {
            item.value = item.unit;
            item.label = item.unit;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getkategoribarang() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getsupplierbykode?kode_supplier=" + this.isidata.kodesupplier;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getkategori_barang_list_tipe";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.tipebrg = resp.data.data;
          this.tipebrg.forEach((item) => {
            item.value = item.tipe;
            item.label = item.tipe;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getsupplier() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getsupplierbykode?kode_supplier=" + this.isidata.kodesupplier;
      const urlAPIget =
        this.$apiurl +
        "supplier/getsupplierbykode?kode_supplier=" +
        this.isidata.kodesupplier;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Supplier Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.namasupplier = resp.data.data.nama_supplier;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    EditData(id) {
      this.isFrmeditData = true;
      this.addData = false;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "barang/getbarangbyid?id=" + id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.kodebarang = resp.data.data.kode_barang;
          this.isidata.namabarang = resp.data.data.nama_barang;
          this.valueunit = resp.data.data.unit;
          this.valuetype = resp.data.data.tipe;
          this.isidata.idxbarang = resp.data.data.id;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddAlias(id) {
      this.addAlias = true;
      this.addData = false;
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbyid?id="+this.$route.params.id;
      const urlAPIget = this.$apiurl + "barang/getbarangbyid?id=" + id;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/getalldatabarang_alias?length=1&kode_barang_header="+this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.isidata.kodebarangalias = resp.data.data.kode_barang;
          this.isidata.namabarang = resp.data.data.nama_barang;
          // this.isidata.aliasbarang = resp.data.data.alias;
          // this.isidata.kodesupplier = resp.data.data[0].kode_supplier;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kode = this.isidata.kodebarang;
      var nama = this.isidata.namabarang;
      var unit = this.valueunit;
      var tipe = this.valuetype;
      var idx = this.isidata.idxbarang;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unit == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tipe == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang: this.isidata.kodebarang,
          nama_barang: this.isidata.namabarang,
          unit: this.valueunit,
          tipe: this.valuetype,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/barang/updatebarang/"+ idx;
        const urlAPIsavebrg = this.$apiurl + "barang/updatebarang/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIsavebrg, paramdata, { headers: headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.valueunit = "";
              this.valuetype = "";
              this.isFrmeditData = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async saveAliasData() {
      this.loading = true;
      var kode = this.isidata.kodebarangalias;
      var nama = this.isidata.aliasbarang;
      var kodesup = this.isidata.kodesupplier;
      var namasup = this.isidata.namasupplier;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodesup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namasup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang_header: this.isidata.kodebarangalias,
          alias: this.isidata.aliasbarang,
          kode_supplier: this.isidata.kodesupplier,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPISaveData = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/savebarang_alias";
        const urlAPISaveData = this.$apiurl + "barang_alias/savebarang_alias";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              // this.isidata.kodebarangalias = ""
              this.loading = false;
              this.fetchData();
              this.addData = true;
              this.isidata.aliasbarang = "";
              this.isidata.kodesupplier = "";
              this.isidata.namasupplier = "";
              this.isFrmeditData = false;
              this.addAlias = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async saveData() {
      this.loading = true;
      var kode = this.isidata.kodebarang;
      var nama = this.isidata.namabarang;
      var unit = this.valueunit;
      var tipebrg = this.valuetype;

      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unit == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tipebrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang: this.isidata.kodebarang,
          nama_barang: this.isidata.namabarang,
          unit: this.valueunit,
          tipe: this.valuetype,
          kode_user: kodeuser
        };
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/barang/savebarang";
        const urlAPIsavebrg = this.$apiurl + "barang/savebarang";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavebrg, paramdata, { headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.valueunit = "";
              this.valuetype = "";
              this.loading = false;
              this.fetchData();
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    searchdata() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?cari=" + this.namesearch+"&length="+this.pageSize;
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize;
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are You Sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          // const urlAPIbrgdel = "http://26.183.23.191/inventory/backend/inventory/api/barang/hapusbarang/" + id;
          const urlAPIbrgdel = this.$apiurl + "barang/hapusbarang/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
